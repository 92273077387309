<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="form.name"
                :disabled="true"
                :counter="50"
                :label="$t('PROMOTION.DETAILCOUPON.NAME')"
                :rules="form.nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-form ref="form" @submit.prevent="submitForm">
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="form.searchKeyword"
                    :disabled="datatable.loading"
                    :label="$t('SHARED.KEYWORD')"
                    :hint="$t('PROMOTION.DETAILCOUPON.KEYWORD_HINT')"
                    prepend-icon="mdi-file-document-box-search-outline"
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.redeemCoupon"
                    :disabled="datatable.loading"
                    :items="form.redeemCouponItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('PROMOTION.DETAILCOUPON.REDEEN_COUPON')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.isUsed"
                    :disabled="datatable.loading"
                    :items="form.isUsedItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('PROMOTION.DETAILCOUPON.IS_USED')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="datatable.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon v-if="!datatable.loading" left>mdi-database-search</v-icon>
                    <v-icon v-if="datatable.loading" left>fa fa-spinner fa-spin</v-icon>
                    {{ $t("SHARED.SEARCH_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="datatable.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </div>
            </v-form>

            <v-divider class="my-4"></v-divider>

            <v-subheader>
              <v-icon left>mdi-table-search</v-icon>
              {{ $t("SHARED.SEARCH_RESULT") }}
              <v-spacer></v-spacer>

              <v-btn
                color="red"
                dark
                class="mr-4"
                type="button"
                @click="deleteAll()"
              >
                <v-icon left
                >mdi-delete</v-icon>
                {{ $t("PROMOTION.DETAILCOUPON.DELETE_ALL") }}
              </v-btn>
            </v-subheader>

            <v-data-table
              :headers="datatable.headers"
              :items="datatable.items"
              :loading="datatable.loading"
              :options.sync="datatable.options"
              :server-items-length="datatable.total"
              :footer-props="{
                'items-per-page-options': [30, 50, 100],
              }"
              multi-sort
            >
            <template v-slot:item.id="{ item }">
                <v-icon v-if = "item.isUsed == false"
                  medium
                  @click="deleteItem(item.id)"
                >
                  mdi-delete
                </v-icon>   
   
              </template>
              </v-data-table>
            <v-dialog 
              v-model="datatable.deleteDialog" 
              persistent  
              max-width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.DELETE_DIALOG_HEADER") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("SHARED.CONFIRM_DELETE_DIALOG_TEXT") }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="datatable.deleteDialog = false"
                    >{{ $t("SHARED.CANCEL_BUTTON") }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmDeleteItem()"
                    >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

             <v-dialog 
              v-model="datatable.deleteAllDialog" 
              persistent  
              max-width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.DELETE_DIALOG_HEADER") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("SHARED.CONFIRM_DELETE_DIALOG_TEXT") }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="datatable.deleteAllDialog = false"
                    >{{ $t("SHARED.CANCEL_BUTTON") }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmDeleteAll()"
                    >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="datatable.dialog" persistent max-width="300">
              <v-card>
                <v-card-title class="headline">
                  {{ datatable.dialogHeader }}
                </v-card-title>
                <v-card-text>
                    {{ datatable.dialogText }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="closeDialog"
                    >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>




<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      form: {
        searchKeyword: "",
        redeemCoupon: null,
        redeemCouponItems: [
          {
            text: this.$t("PROMOTION.DETAILCOUPON.REDEEM_IS_YES"),
            value: true
          },
          {
            text: this.$t("PROMOTION.DETAILCOUPON.REDEEM_IS_NO"),
            value: false
          }
        ],
        isUsed: null,
        isUsedItems: [
          {
            text: this.$t("PROMOTION.DETAILCOUPON.IS_USED_YES"),
            value: true
          },
          {
            text: this.$t("PROMOTION.DETAILCOUPON.IS_USED_NO"),
            value: false
          }
        ],
        valid: true,
        dialog: false,
        loading: false,
        errors: [],
        promotionId: null,
        name: ""
      },
      datatable: {
        headers: [
          {
            text: "CouponSeriesName",
            value: "couponSeriesName",
            align: "center"
          },
          {
            text: "CouponType",
            value: "couponType",
            align: "center"
          },
          {
            text: "IsPercentDiscount",
            value: "isPercentDiscount",
            align: "center",
            sortable: false,
            width: "10%"
          },
          {
            text: "DiscountPercent",
            value: "discountPercent",
            align: "center",
            width: "10%"
          },
          {
            text: "DiscountAmount",
            value: "discountAmount"
          },
          {
            text: "CashAmount",
            value: "cashAmount"
          },
          {
            text: "CouponNumber",
            value: "coupon",
            align: "center"
          },
          {
            text: "ExpireDate",
            value: "expireDateString",
            align: "center"
          },
          {
            text: "IsUsed",
            value: "isUsed",
            align: "center",
            sortable: false
          },
          {
            text: "UseInBranch",
            value: "usedInBranchId",
            align: "center"
          },
          {
            text: "UsedDate",
            value: "usedDateString",
            align: "center"
          },
          {
            text: "UsedByMemberCardId",
            value: "usedByMemberCardId",
            align: "center",
            sortable: false
          },
          {
            text: this.$t("PROMOTION.DETAILCOUPON.ACTION"),
            value: "id",
            align: "center",
            sortable: false,
          },
        ],
        items: [],
        total: 0,
        loading: true,
        options: {
          sortBy: [],
          sortDesc: [true],
          itemsPerPage: 30
        },
        deleteDialog: false,
        deleteDialogId : null,
        deleteAllDialog: false,
        dialog: false,
        dialogText: null,
        dialogHeader: null,
      }
    };
  },
  computed: {
    title() {
      return this.$t("MENU.PROMOTION.DETAILCOUPON");
    }
  },
  watch: {
    "datatable.options": {
      handler() {
        this.getDataFromApi().then(data => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      this.getDataFromApi().then(data => {
        this.datatable.total = data.total;
        this.datatable.items = data.items;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.submitForm();
    },
    getPromotionNameApi(id) {
      this.form.loading = true;
      return new Promise(() => {
        ApiService.setHeader();
        ApiService.get("/Api/Promotion", id)
          .then(({ data }) => {
            this.form.promotionId = data.promotionId;
            this.form.name = data.name;
          })
          .finally(() => {
            this.form.loading = false;
          });
      });
    },
    getDataFromApi() {
      this.datatable.loading = true;
      return new Promise(resolve => {
        ApiService.setHeader();
        ApiService.post("/Api/PromotionCoupon/GetCoupon", {
          GroupBy: this.datatable.options.groupBy,
          GroupDesc: this.datatable.options.groupDesc,
          ItemsPerPage: this.datatable.options.itemsPerPage,
          Page: this.datatable.options.page,
          SortBy: this.datatable.options.sortBy,
          SortDesc: this.datatable.options.sortDesc,
          PromotionId: parseInt(this.$route.params.id),
          SearchKeyword: this.form.searchKeyword,
          RedeemCoupon: this.form.redeemCoupon
            ? this.form.redeemCoupon.value
            : null,
          IsUsed: this.form.isUsed ? this.form.isUsed.value : null
        })
          .then(({ data }) => {
            resolve({
              items: data.items,
              total: data.total
            });
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      });
    },
    deleteItem(id){
      this.datatable.deleteDialog = true;
      this.datatable.deleteDialogId = id;
    },
    deleteAll(){
      this.datatable.deleteAllDialog = true;
    },
    closeDialog() {
      this.datatable.dialog = false;
      this.datatable.dialogText = null;
      this.datatable.dialogHeader = null;
      this.submitForm();
    },
    confirmDeleteItem(){
      this.datatable.dialogHeader = this.$t("SHARED.DELETE_RESULT_DIALOG_HEADER");
      ApiService.setHeader();
      ApiService.update("/Api/PromotionCoupon", this.datatable.deleteDialogId +"/DeleteItem")
        .then(({ data }) => {
          this.datatable.dialog = true;
          if (data.message) {
            this.datatable.dialogText = data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        })
        .catch(({ response }) => {
          this.datatable.dialog = true;
          if (response.data) {
            this.datatable.dialogText = response.data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        });

      this.datatable.deleteDialogId = null;
      this.datatable.deleteDialog = false;
    },
    
    confirmDeleteAll(){
      this.datatable.dialogHeader = this.$t("SHARED.DELETE_RESULT_DIALOG_HEADER");
      ApiService.setHeader();
      ApiService.update("/Api/PromotionCoupon", this.form.promotionId +"/DeleteAll")
        .then(({ data }) => {
          this.datatable.dialog = true;
          if (data.message) {
            this.datatable.dialogText = data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        })
        .catch(({ response }) => {
          this.datatable.dialog = true;
          if (response.data) {
            this.datatable.dialogText = response.data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        });
      this.datatable.deleteAllDialog = false;
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PROMOTION.SECTION"), route: "/Promotion" },
      { title: this.$t("MENU.PROMOTION.DETAILCOUPON") }
    ]);

    this.getPromotionNameApi(this.$route.params.id);
  }
};
</script>

<style lang="scss" scoped></style>